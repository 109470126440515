const kz = {
  action: {
    participate: 'Қатысу',
    disable: 'Лотереяны өшіру',
    support: 'Толық ақпарат алу үшін немесе туындаған мәселені шешу үшін \n' +
      '<a href="tel:8-800-080-9351">8-800-080-9351</a> тегін нөміріне қоңырау шалыңыз'
  },
  menu: {
    logo: 'Ұлттық<br />лотерея',
    participate: 'Лотереяға қатысу',
    prizes: 'Жүлделер',
    rules: 'Лотерея ережелері',
    feedback: 'Кері байланыс',
    disable: 'Лотереяны өшіру',
    localeKz: 'kz',
    localeRu: 'ру',
    menu: 'МӘЗІР'
  },
  common: {
    titleApp: 'Ұлттық лотерея'
  },
  banner: {
    header: '«Сенің Сәттілігің» лотереяысында миллион ұтып алыңыз',
    label: '<b>TELE2</b> және <b>ALTEL</b> абоненттері үшін лездік лотерея!',
    desc: 'Күн сайын билеттер алыңыз, ұтып алыңыз және жаңа миллионер болыңыз!'
  },
  promo: {
    header: 'Күн сайын қатысыңыз',
    desc: '«Сәттілік» лотереясына жазылыңыз және күн сайын SMS арқылы бір лотерея билетін алыңыз. ',
    appeal: 'Билеттер неғұрлым көп болса, миллион ұтып алу мүмкіндігі соғұрлым жоғары болады!'
  },
  prizes: {
    header: 'Жүлделер',
    label: '«Сенің сәттілігің» лотереясының жүлде қоры:',
    prize0: '2 648 176 теңге бойынша 100 ұтыстар',
    prize1: '2 575 250 теңге бойынша 200 ұтыстар',
    prize2: '463 421 теңге бойынша 400 ұтыстар',
    prize3: '71 258 теңге бойынша 1 000 ұтыстар',
    prize4: '12 021 теңге бойынша 2 000 ұтыстар',
    prize5: '4 176 теңге бойынша 4 000 ұтыстар',
    superPrizeLabel: '3 супер-жүлде',
    superPrize: '1 000 000 ₸',
    buttonSubscribe: 'Қатысу',
    buttonUnsubscribe: 'Лотереяны өшіру'
  },
  cards: {
    desc1: '«Сәттілік» лотереясы Қазақстан Республикасының «Сәтті Жұлдыз» \n' +
      'АҚ ұлттық лотереясымен әзірлеген.',
    desc2: 'Толық ақпарат алу үшін немесе туындаған мәселені шешу үшін \n' +
      '<a href="tel:8-800-080-9351">8 800 080 93 51</a> тегін нөміріне қоңырау шалыңыз'
  },
  rules: {
    header: 'Лотерея ережелері',
    rule0: 'Күн сайын сіз бір лотерея билетін аласыз.',
    rule1: 'Билет бірден ойнатылады, ұтқан жағдайда сізге бірден хабарлама келеді.',
    rule2: 'Егер сіздің билетіңіз ұтып алса, ақшалай жүлде сіздің абоненттік шотыңызға бірден түседі. Егер сіз супер жүлде жеңіп алсаңыз, ұтысты аудару үшін біз сізге хабарласамыз.',
    rule3: 'Егер сіздің билетіңіз ұтпаса - қобалжымаңыз! Сіз бақытыңызды сынап, күн сайын тағы бір лотерея билетін сатып ала аласыз.',
    rule4: 'Миллион ұтып алу мүмкіндігін арттыру үшін күн сайын лотереяға қатысыңыз!',
    button: 'Лотереяның барлық ережелері'
  },
  winners: {
    title: 'Лотерея жеңімпаздары',
    winner0: {
      name: 'Анвар Т.',
      city: 'Алматы',
      prize: '1 000 000 ₸'
    },
    winner1: {
      name: 'Нурлан С.',
      city: 'Алматы',
      prize: '1 000 000 ₸'
    },
    winner2: {
      name: 'Анвар Т.',
      city: 'Алматы',
      prize: '1 000 000 ₸'
    }
  },
  modal: {
    subscribe: {
      title: 'Лотереяға қатысыңыз',
      desc: 'Күн сайын лотерея билеттерін алыңыз және миллион ұтып алыңыз!<br /><br />' +
        '«Сенің Сәттілігің» лотереясына қатысу үшін сізге жазылу керек, құны 100 теңге/күн.<br /><br />' +
        '<b>Жазылымды іске қосу үшін ұялы телефонның</b> пернетақтасынан USSD <a href="tel:*88%23">*88#</a>теріңіз.<br /><br />' +
        '<b>Тағы бір немесе бірнеше билет сатып алу үшін,</b> *88*N# командасын теріңіз, мұндағы N - 1-ден 10-ға дейінгі билеттер саны. Мысалы, 10 билет сатып алу үшін, <a href="tel:*88*10%23">*88*10#</a> командасын теріңіз.' +
        ' Сатып алынған әр билеттің құны 100 ₸.<br /><br />' +
        'Нақты ақпарат алу немесе туындаған мәселені шешу үшін <a href="tel:8-800-080-9351">8 800 080 93 51</a> тегін нөміріне соғыңыз.',
      button: 'Тамаша'
    },
    unsubscribe: {
      title: 'Жазылымды басқару',
      desc: 'Лотереяға қатыса отырып, Сіз күнделікті билеттермен бірге миллион теңге ұтып алуға мүмкіндік аласыз!<br /><br />' +
        'Лотереяға жазылуды өшіру үшін <a href="tel:*88*0%23">*88*0#</a> теріңіз немесе <a href="tel:8-800-080-9351">8 800 080 93 51</a> қызу желісіне соғыңыз.',
      button: 'Тамаша'
    }
  },
  card: {
    desc: '«Сенің Сәттілігің» лотереясы Қазақстан Республикасының «Сәтті Жұлдыз» АҚ ұлттық лотереясымен бірлесіп әзірлеген.',
    feedback: 'Толық ақпарат алу үшін немесе туындаған мәселені шешу үшін \n' +
      '<a href="tel:8 800 080 93 51">8 800 080 93 51</a> тегін нөміріне қоңырау шалыңыз.'
  },
  footer: {
    copyright: `${new Date().getFullYear()} © «Сәтті Жұлдыз» АҚ`,
    feedback: 'Кері байланыс',
    terms: 'Пайдалану шарттары'
  }
};

export default kz;
